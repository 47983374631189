import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

function PolicyDialog({ open, handleClose, title, content }) {
  return (
      <Dialog open={open} onClose={() => handleClose()}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
              <DialogContentText component="div">
                  {content}
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => handleClose()} color="primary">
                  Cancel
              </Button>
          </DialogActions>
      </Dialog>
  );
}

export default PolicyDialog