import { Card, CardContent, Grid, Typography } from "@mui/material"

const CustomCard = ({ icon, title, onClick }) => {
  return (
    <Grid xs={6} item onClick={onClick}>
      <Card
        sx={{
          borderRadius: "20px",
          boxShadow: 3,
          backgroundColor: "rgba(0,0,0,0)",
          transition: 'transform 0.1s, box-shadow 0.1s',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: 8,
            cursor: 'pointer',
          },
          '&:active': {
            transform: 'translateY(2px) !important',
            boxShadow: 1,
            cursor: 'pointer',
          },
        }}
      >
        <CardContent sx={{ textAlign: 'center' }}>
          {icon}
          <Typography variant="h6" sx={{ marginTop: 2 }}>{title}</Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default CustomCard