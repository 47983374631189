const TestWithTooltip = ({title, spaceBefore=true, spaceAfter=true, ...props}) => {
  return (
    <>
      {spaceBefore && <> </>}
      <span
        title={title && title}
        style={{
          backgroundColor: "RGBA(128, 128, 128, 0.125)",
          borderRadius: "8px",
          padding: "2px 8px"
        }}
      >
        {props.children}
      </span>
      {spaceAfter && <> </>}
    </>
  )
}

export default TestWithTooltip