import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useField } from 'formik';
import { Button, FormHelperText } from '@mui/material';
import PolicyDialog from './PolicyDialog';
import PrivacyPolicy from './PrivacyPolicy'

function AcceptPoliciesCheckbox( ...props) {
  const [field, meta, helpers] = useField({...props, name: "accept_policies"})
  const [openPolicy, setOpenPolicy] = useState(null);
  
  const handlePolicyClick = (policyType) => {
      setOpenPolicy(policyType);
  };

  const handleClose = () => {
      setOpenPolicy(null);
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={field.value}
          />
        }
        
        label={
          <span>
            I accept the <Button sx={{ padding: 0, textTransform: 'none' }} onClick={() => handlePolicyClick('privacy')}>Privacy Policy</Button>.
          </span>
        }
      />
      
      {meta.touched && Boolean(meta.error) && (
        <FormHelperText error sx={{mx: "14px"}}>
          {meta.touched && meta.error}
        </FormHelperText>
      )}

      {openPolicy === 'terms' && (
        <PolicyDialog open={true} handleClose={handleClose} title="Terms & Conditions" content={<PrivacyPolicy/>} />
      )}
      {openPolicy === 'privacy' && (
        <PolicyDialog open={true} handleClose={handleClose} title="Privacy Policy" content={<PrivacyPolicy/>} />
      )}
    </FormGroup>
  );
}

export default AcceptPoliciesCheckbox