import React, { useEffect } from 'react';
import { TextField, Button, Stack, Box, FormHelperText } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useField } from 'formik';

const CustomAttachmentInput = ({ onChange, title, ...props }) => {
  const [field, meta, helpers] = useField({...props, name: "attachment"})
  
  useEffect(() => {
    helpers.setTouched(true)
  }, [helpers])

  const temp = {...field} 
  delete temp.value
  delete temp.onChange

  const resetHandler = () => {
    helpers.setValue(meta.initialValue)
    if (onChange) {
      onChange()
    }
  }

  const handleFileChange = (event) => {
    helpers.setValue(event.target.files[0]);
    if (onChange) {
      onChange(event.target.files[0])
    }
  };
  
  return (
    <>
      <TextField
        {...temp}
        {...props}
        onChange={handleFileChange}
        value={""}
        variant="outlined"
        type="file"
        inputProps={{ accept: '.pdf, .xls, .xlsx, .doc, .docx' }}
        sx={{ display: 'none' }}
        id="contained-button-file"
      />
      <Stack direction="row" spacing={1}>
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span">
            {title ? title : "Upload Document"}
          </Button>
        </label>
        <Box sx={{ flexGrow: 1 }} />
        {(field.value !== meta.initialValue) &&
          <Button startIcon={<RestartAltIcon />} variant="contained" component="span" onClick={resetHandler} color="secondary">
            Remove attachment
          </Button>
        }
      </Stack>

      {(field.value && field.value !== meta.initialValue) &&
        <p>{"Selected file: " + field.value.name}</p>}
        
      {meta.touched && Boolean(meta.error) && (
        <FormHelperText error sx={{mx: "14px"}}>
          {meta.touched && meta.error}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomAttachmentInput;