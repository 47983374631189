import React from 'react';
import { TextField } from '@mui/material';
import { Field, useField } from 'formik';

const CustomLastNameInput = ({ disabled, label, ...props }) => {
  const [field, meta, helpers] = useField({...props, name: "last_name"})
  
  return (
      <Field name={field.name}>
        {() => (
          <TextField
            {...field}
            {...props}
            fullWidth
            label="Last Name"
            type="text"
            margin="normal"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            disabled={disabled}
          />
        )}
    </Field>
  )
}

export default CustomLastNameInput;