import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production'
    ? 'https://api.selence.com'
    : 'http://localhost:8000';

const API = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
});

API.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  response => response,
  error => {
    // Handle errors
    console.log(error)

    if (error.response.status === 401) {
      // Redirect user to login page, etc.
    }
    return Promise.reject(error);
  }
);

export default API;