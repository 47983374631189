import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Field, useField } from 'formik';
import EmailIcon from '@mui/icons-material/Email';

const CustomEmailInput = ({ disabled, label, ...props }) => {
  const [field, meta, helpers] = useField({...props, name: "email"})

  return (
      <Field name={field.name}>
        {() => (
          <TextField
            {...field}
            {...props}
            fullWidth
            label="Email"
            type="email"
            margin="normal"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              )
            }}
            disabled={disabled}
          />
        )}
    </Field>
  )
}

export default CustomEmailInput;