import { Form, Formik } from "formik";

const CustomForm = ({ validationSchema, initialValues, handleSubmit, ...props }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form>
          {props.children}
        </Form>
      )}
    </Formik>
  )
};

export default CustomForm