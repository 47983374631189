import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";
import * as Yup from 'yup';
import { toast } from "react-toastify";

import API from "../api";

import CustomForm from "./CustomForm";
import CustomFirstNameInput from "./CustomFirstNameInput";
import CustomLastNameInput from "./CustomLastNameInput";
import CustomEmailInput from "./CustomEmailInput";
import CustomPhoneInput from "./CustomPhoneInput";
import CustomAttachmentInput from "./CustomAttachmentInput";
import CustomMessageInput from "./CustomMessageInput";
import CustomBusinessNameInput from "./CustomBusinessNameInput";
import AcceptPoliciesCheckbox from "./AcceptPoliciesCheckbox";

const validationSchema = Yup.object({
  first_name: Yup.string()
    .required('First name is required')
    .min(2, 'First name must be at least 2 characters long')
    .max(255, "First name must be no longer than 255 characters"),
  last_name: Yup.string()
    .required('Last name is required')
    .min(2, 'Last name must be at least 2 characters long')
    .max(255, "Last name must be no longer than 255 characters"),
  business_name: Yup.string()
    .required('Business name is required')
    .min(2, 'Business name must be at least 2 characters long')
    .max(255, "Business name must be no longer than 255 characters"),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .max(255, "Email must be no longer than 255 characters"),
  message: Yup.string()
    .required('Message is required')
    .min(20, 'Message must be at least 20 characters long')
    .max(2000, 'Message must be no longer than 2000 characters'),
  attachment: Yup.mixed().nullable()
    .test(
      "fileSize",
      "Max file size is 5MB",
      value => !value || (value && value.size <= 1024 * 1024 * 4)
    ),
  accept_policies: Yup.boolean()
    .required("You must accept the privacy policy")
    .oneOf([true], "You must accept the privacy policy"),
});

const initialValues = {
  first_name: '',
  last_name: '',
  business_name: '',
  email: '',
  phone: '',
  message: '',
  attachment: null,
  accept_policies: false,
};

const BusinessContactForm = ({ open, handleClose }) => {
  const submitForm = async (values) => {
    try {
      const response = await API.post('api/business-contact/', values, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast.success('Message sent successfully!');
        handleClose()
      } else {
        toast.error('Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
    >
      <CustomForm
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={submitForm}
      >
        <DialogTitle>Business Contact Form</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out this form if you are a business interested in our services.
          </DialogContentText>
          
          <CustomFirstNameInput />
          <CustomLastNameInput />
          <CustomBusinessNameInput />
          <CustomEmailInput />
          <CustomPhoneInput />
          <CustomMessageInput />
          <CustomAttachmentInput title={"Upload Document"} />
          <Divider sx={{padding: 1}}/>
          <AcceptPoliciesCheckbox />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type='submit' color="primary">
            Submit
          </Button>
        </DialogActions>
      </CustomForm>
    </Dialog>
  )
};

export default BusinessContactForm